// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tcf20container{position:relative;padding:10px 0 10px 0;background:#fff;width:100%}.tcf20container .logo{width:20%;height:40px;max-width:130px;margin:20px auto 0 auto;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain}.tcf20container .header{width:calc(100% - 60px);height:auto;margin:0 30px}.tcf20container .v-lazy-image{filter:blur(10px);transition:filter .7s}.tcf20container .v-lazy-image-loaded{filter:blur(0)}.tcf20container .title{text-transform:uppercase;color:#4f5c68;font-size:1.8em;font-weight:800;line-height:1em;text-align:center;width:100%;display:inline-block;margin:10px auto 10px auto}@media screen and (min-width:768px){.tcf20container .title{font-size:3.2em}}@media screen and (min-width:1024px){.tcf20container .title{font-size:5em}}.tcf20container .text{padding:0 10px 130px 10px;font-size:1em;color:#4c5d6a;text-align:center}.tcf20container .text.noheader{margin:0}.tcf20container .text.notitle{margin:20px 0 0 0}.tcf20container .text span{color:#688194;text-decoration:underline}.tcf20container .text.grey{color:#aab4bc}@media screen and (min-width:768px){.tcf20container .text{font-size:1.8em;padding:0 10px 190px 10px}}@media screen and (min-width:1024px){.tcf20container .text{font-size:2.1em;padding:0 10px 200px 10px}}.tcf20container .text.jinke{padding:0 10px 250px 10px}@media screen and (min-width:768px){.tcf20container .text.jinke{font-size:1.8em;padding:0 10px 310px 10px}}@media screen and (min-width:1024px){.tcf20container .text.jinke{font-size:2.1em;padding:0 10px 320px 10px}}.tcf20container .text.reducepadding{padding:0 10px 60px 10px!important}.absolutefooter .tcf20container{height:100%}", ""]);
// Exports
module.exports = exports;
