<template>
  <Layout>
    <div
      v-if="isiOS"
      class="tcf20container regcontainer piplcontainer"
    >
      <div class="logo" />
      <img
        v-if="orientation !== 'landscape'"
        class="header"
        alt="Talking Tom and Friends"
        :src="require('../../assets/images/pp_friends.jpg')"
      >
      <div class="text notitle">
        {{ content.pipl_screen }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_pp')"
          :text="content.pipl_screen1"
        />
        {{ content.pipl_screen2 }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_childrens_pp')"
          :text="content.pipl_screen3"
        />
        {{ content.pipl_screen4 }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_eula')"
          :text="content.pipl_screen5"
        />
        {{ content.pipl_screen6 }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_3rdparty_list')"
          :text="content.pipl_screen7"
        />
        {{ content.pipl_screen8 }}
      </div>
      <Footer
        module="pipl"
        screen="pipl"
      />
    </div>
    <div
      v-else
      class="tcf20container regcontainer piplcontainer"
    >
      <h1
        class="title"
        :dir="dir"
      >
        奥飞特七隐私政策
      </h1>
      <div class="text jinke">
        我们非常重视您的个人信息保护，在您进入游戏前，请您认真阅读 <InternalLink
          route="/pipl/eula"
          text="《用户协议》"
        /> <InternalLink
          route="/pipl/privacy"
          text="《隐私政策》"
        /> <InternalLink
          route="/pipl/children-privacy"
          text="《儿童隐私保护声明》"
        /> <InternalLink
          :route="storePermission"
          text="《应用权限说明》"
        /> <InternalLink
          route="/pipl/third-share-list"
          text="《第三方共享清单》"
        />，您同意后方可使用完整服务。<br><br>
        为了向您正常提供产品和服务，根据你所获取产品和服务的平台方的要求不同，可能会由我们或第三方平台收集、储存和使用下列与您有关的信息或需要获得访问您信息的权限。如果您不同意被收集或访问相关必要信息，可能无法使用我们的产品和服务，或者无法达到相关服务拟达到的效果。以上所述第三方平台指的是您下载获得游戏产品的平台。您授权我们或第三方平台收集或访问您的以下个人信息：<br><br>
        · 为了给您提供正常的游戏功能和优质的游戏体验或售后服务，当您注册或使用我们的服务时，我们或第三方平台将会收集您在注册账户或使用我们的产品和服务时，向我们提供的电话号码、电子邮件、账号ID或银行卡号；<br><br>
        · 根据国家相关部门要求，您在使用产品或服务前需要通过实名认证，实名认证时需要您输入姓名、身份证件类型、身份证件号码。我们并不会收集和存储您的上述信息，上述信息是为了履行法定义务，核验用户真实身份、识别用户年龄以及保障系统和服务安全。如您不提供这类信息，您将无法使用我们的产品和服务。
      </div>
      <Footer
        module="pipl"
        screen="jinke"
      />
    </div>
  </Layout>
</template>

<script>
import Footer from '@components/Footer.vue'
import ExternalLink from '@components/ExternalLink.vue'
import InternalLink from '@components/InternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Footer,
    ExternalLink,
    InternalLink,
  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
      isiOS: true,
      store: ''
    }
  },
  computed: {
    storePermission() {
      const s = ['huawei', 'oppo', 'uc', 'qihoo', 'vivo', 'xiaomi', 'honor', 'leyuan233', 'tiktok', 'm4399'];
      return '/pipl/permission-' + (s.includes(this.store) ? this.store : 'huawei')
    }
  },
  created() {
    this.$compliance.initCollector('consent_pipl')
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.isiOS = this.$compliance.isPlatform('ios')
    this.store = this.$compliance.getStore()
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.tcf20container {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 130px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: center;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    &.grey {
      color: #aab4bc;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 190px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
      padding: 0 10px 200px 10px;
    }
    &.jinke {
      padding: 0 10px 250px 10px;
      @media screen and (min-width: 768px) {
        font-size: 1.8em;
        padding: 0 10px 310px 10px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2.1em;
        padding: 0 10px 320px 10px;
    }

    }
    &.reducepadding {
      padding: 0 10px 60px 10px !important;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
